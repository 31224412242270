import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery }    from 'gatsby';
import { isMobile }                   from 'react-device-detect';

// import components
import Seo         from '../components/common/Seo';
import Filter      from '../components/elements/Filter';
import PageMeta    from '../components/elements/PageMeta/index';
import Main        from '../components/BlogMain/Main';
import PostsGrid   from '../components/BlogMain/PostsGrid';
import LoadedPosts from '../components/elements/Post/LoadedPosts';

const IndexPage = () => {
  const data = useStaticQuery( graphql`
    {
      allApiPoints {
        nodes {
          metas {
            data {
              description
              title
            }
          }
        }
      }
    }
  `);

  // define the default component state
  const [isMobileDevice, setIsMobileDevice] = useState( false );

  /**
   * Check if a mobile device
   */
  useEffect( () => { setIsMobileDevice( isMobile ) }, [data] );

  // get metadata
  const nodesData = data.allApiPoints.nodes[0].metas[0].data[0];
  const { title, description } = nodesData;

  return (
    <>
      <Seo title={ title } description={ description } lang="de" />
      { !isMobileDevice ? <Filter /> : false }
      <PageMeta />
      <Main />
      <PostsGrid />
      <LoadedPosts />
    </>
  )
};

export default IndexPage;
